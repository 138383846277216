/* Material UI */
import { Button, Box } from "@mui/material";

import CurrencyFormat from "react-currency-format";

/* Icons */
import ship from "assets/img/ship.png";

const container = {
  padding: "15px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

const card = {
  padding: "15px",
  backgroundColor: "white",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "20px",
  fontWeight: "300",
};

const row = {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
  fontFamily: "Poppins, sans-serif",
  fontWeight: "600",
};

const rowMini = {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
};

const strongFont = {
  fontWeight: "600",
  fontFamily: "Poppins, sans-serif",
};

const buttonStyle = {
  marginTop: "30px",
  height: "40px",
  width: "300px",
  fontFamily: "Poppins, sans-serif",
  fontWeight: "700",
  fontSize: '15px'
};

export default function ShoppingCart({ allAmounts, price, updatePay, saving }) {
  return (
    <div style={container}>
      <div style={card}>
        <Box style={row} sx={{ fontSize: { xs: "15px", md: "20px" } }}>
          Precio del carrito
          <CurrencyFormat
            value={price}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
            prefix="$"
            style={strongFont}
          />{" "}
        </Box>

        <Box style={rowMini} sx={{ fontSize: { xs: "12px", md: "15px" } }}>
          IVA incluido
        </Box>

        <hr />
        <div style={row}>
          Costo de envío
          <div style={{ ...strongFont, color: "#E21608" }}>
            {price === 0 && 0}
            {price > 0 && (price >= 549 && price > 0 ? "¡Gratis!" : "$ 149.00")}
          </div>
        </div>

        <Box style={rowMini} sx={{ fontSize: { xs: "12px", md: "15px" } }}>
          Envío estándar
        </Box>
        <Box style={rowMini} sx={{ fontSize: { xs: "12px", md: "15px" } }}>
          <div>
            Tiempo de entrega estimado es de 2 a 5 días hábiles según el
            domicilio de entrega
          </div>
          <img src={ship} alt="img" style={{ height: "40px" }} />
        </Box>
        {saving > 0 && (
          <Box style={row} sx={{ fontSize: { xs: "15px", md: "17px" } }}>
            <div style={strongFont}> Ahorras </div>
            <div style={strongFont}>
              <CurrencyFormat
                value={saving}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix="-$"
                style={{
                  fontFamily: "Nunito Sans, sans-serif",
                  fontWeight: "700",
                  color: "#E21608",
                }}
              />{" "}
            </div>
          </Box>
        )}

        <hr />

        <Box style={row} sx={{ fontSize: { xs: "13px", md: "15px" } }}>
          <div style={strongFont}> Subtotal </div>
          <div style={strongFont}>
            <CurrencyFormat
              value={
                parseFloat(price) +
                parseFloat(price >= 549 || parseInt(price) === 0 ? 0 : 149)
              }
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix="$"
              style={strongFont}
            />{" "}
          </div>
        </Box>
      </div>

      <Button
        style={buttonStyle}
        color="primary"
        variant="contained"
        onClick={() => {
          if (!allAmounts) alert("Selecciona alguna cantidad de productos");
          else updatePay(1);
        }}
      >
        Continuar con el pago
      </Button>
    </div>
  );
}
