import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { PRODUCT, SLUG } from "helpers/helpers";

export default function BurguerMenu() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const menuRef = useRef(null); // Referencia para el menú

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickRoute = (route) => {
    history.push(route);
    if (route.startsWith("Categoria")) window.location.reload();
    setIsOpen(false); // Cerrar el menú al seleccionar una opción
    setIsCategoriesOpen(false); // Cerrar submenú de categorías
  };

  const toggleCategories = () => {
    setIsCategoriesOpen(!isCategoriesOpen);
  };

  // Efecto para cerrar el menú al hacer clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false); // Cerrar menú
        setIsCategoriesOpen(false); // Cerrar submenú
      }
    };

    // Agregar el evento de clic
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Limpiar el evento al desmontar el componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div onClick={toggleMenu} style={{ cursor: "pointer", zIndex: 1000 }}>
        <MenuIcon style={{ fontSize: "30px", color: "white" }} />
      </div>

      {isOpen && (
        <div
          ref={menuRef} // Asignar referencia al menú
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "30vw",
            height: "100vh",
            background: "black",
            padding: "20px",
            zIndex: 1000,
            color: "white",
            boxShadow: "2px 0px 10px rgba(0, 0, 0, 0.5)", // Sombra para dar efecto de profundidad
            fontSize: "14px",
            fontFamily: "Nunito Sans, sans-serif",
            fontWeight: "900",
          }}
        >
          <div
            onClick={toggleMenu}
            style={{
              cursor: "pointer",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CancelIcon style={{ fontSize: "25px", color: "white" }} />
          </div>

          <div
            style={{
              textAlign: "right",
              marginBottom: "5px",
              cursor: "pointer",
              fontFamily: "inherit",
              fontWeight: "inherit",
            }}
            onClick={() => handleClickRoute("/")}
          >
            Inicio
          </div>
          <div style={{ borderBottom: "3px solid white", margin: "5px 0" }} />

          <div
            style={{
              textAlign: "right",
              marginBottom: "5px",
              cursor: "pointer",
              fontFamily: "inherit",
              fontWeight: "inherit",
              display: "flex",
              alignItems: "center",
            }}
            onClick={toggleCategories}
          >
            {isCategoriesOpen ? (
              <ArrowDropUpIcon style={{ marginLeft: "10px", color: "white" }} />
            ) : (
              <ArrowDropDownIcon
                style={{ marginLeft: "10px", color: "white" }}
              />
            )}
            Categorías
          </div>
          <div style={{ borderBottom: "3px solid white", margin: "5px 0" }} />

          {/* Submenú de Categorías */}
          {isCategoriesOpen && (
            <div
              style={{
                marginBottom: "20px",
                background: "black",
                borderRadius: "5px",
              }}
            >
              {Object.keys(SLUG).map((item, index) => {
                return(
                <div
                  style={{
                    textAlign: "right",
                    cursor: "pointer",
                    marginBottom: "15px",
                    fontFamily: "inherit",
                    fontWeight: "inherit",
                    fontSize: '12px',
                    textDecoration: "underline", // Agregado para subrayar
                  }}
                  onClick={() => handleClickRoute(`Categoria/${item}`)}
                >
                  {PRODUCT[SLUG[item]]}{" "}
                </div>);
              })}
            </div>
          )}

          <div
            style={{
              textAlign: "right",
              marginBottom: "5px",
              cursor: "pointer",
              fontFamily: "inherit",
              fontWeight: "inherit",
            }}
            onClick={() => handleClickRoute("/envios-y-pagos")}
          >
            Envíos y Pagos
          </div>
          <div style={{ borderBottom: "3px solid white", margin: "5px 0" }} />
          <div
            style={{
              textAlign: "right",
              marginBottom: "5px",
              cursor: "pointer",
              fontFamily: "inherit",
              fontWeight: "inherit",
            }}
            onClick={() => handleClickRoute("/tips-mantenimiento-auto")}
          >
            Tips Vehículo
          </div>
          <div style={{ borderBottom: "3px solid white", margin: "5px 0" }} />

          <div
            style={{
              textAlign: "right",
              marginBottom: "5px",
              cursor: "pointer",
              fontFamily: "inherit",
              fontWeight: "inherit",
            }}
            onClick={() => handleClickRoute("/contacto-afinazone")}
          >
            Contacto
          </div>

          <div style={{ borderBottom: "3px solid white", margin: "5px 0" }} />

          <div
            style={{
              textAlign: "right",
              marginBottom: "5px",
              cursor: "pointer",
              fontFamily: "inherit",
              fontWeight: "inherit",
            }}
            onClick={() => handleClickRoute("/conoce-afinazone")}
          >
            Conócenos
          </div>
        </div>
      )}
    </div>
  );
}
