import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";

/* Routing */
import { Link } from "react-router-dom";

/* Icons */
import DeleteIcon from "@material-ui/icons/Delete";
import imageBroken from "assets/img/image.svg";

/* Material-UI */
import TextField from "@mui/material/TextField";

/* API */
import { getProductAPI } from "utils/API/Product";
import { getPreviewImageUrlFromProduct } from "utils/API/Pictures";
import { Box } from "@material-ui/core";

const card = {
  padding: "15px",
  backgroundColor: "white",
  fontSize: "20px",
  fontWeight: "300",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const imgStyle = {
  cursor: "pointer",
  width: "150px",
  height: "150px",
  marginRight: "10px",
};

const titleStyle = {
  fontWeight: "300",
  fontSize: "18px",
  marginBottom: "5px",
  fontFamily: "Poppins, sans-serif",
};

const productContainer = {
  alignItems: "center",
  margin: "5px",
};

const container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const quantity = {
  backgroundColor: "white",
  marginLeft: "10px",
  width: "60px",
};

const verticalLine = {
  width: "1px",
  height: "30px",
  marginLeft: "10px",
  marginRight: "10px",
  backgroundColor: "black",
};

export default function ShoppingCarProduct({
  id,
  type,
  description,
  price,
  discount,
  discountPrice,
  amount,
  updateAmount,
  deleteItem,
}) {
  useEffect(() => {
    async function go() {
      if (!(id === null && type === null)) {
        var product = await getProductAPI(id, type);
        if (product !== null) {
          var url = await getPreviewImageUrlFromProduct(id, type);
          setTitle(product.name);
          setUrl(url);
        }
      }
    }

    go();
  }, [id, type]);

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState(null);

  return (
    <div style={card}>
      {url === null && (
        <Link to={`/Producto?product=${type}&id=${id}`} target="_blank">
          <img src={imageBroken} alt="car" style={imgStyle} />
        </Link>
      )}
      {url !== null && (
        <Link to={`/Producto?product=${type}&id=${id}`} target="_blank">
          <img src={url} alt="car" style={imgStyle} />
        </Link>
      )}
      <div style={container}>
        <div style={{ display: "flex", ...productContainer }}>
          <Box style={titleStyle}>
            {" "}
            {title} ({description}){" "}
          </Box>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "15px",
              alignSelf: "flex-start",
            }}
          >
            <DeleteIcon style={{ cursor: "pointer" }} onClick={deleteItem} />
            <div style={verticalLine} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400" }}
              >
                Cantidad:{" "}
              </div>
              <TextField
                inputProps={{
                  min: 0,
                  style: { textAlign: "center", height: "15px" },
                }}
                size="small"
                style={quantity}
                value={amount}
                onChange={(e) => updateAmount(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Box
          style={{
            fontSize: "17px",
            fontWeight: "500",
            fontFamily: "Poppins, sans-serif",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "700" }}>
            {" "}
            Precio Unit:{" "}
            <CurrencyFormat
              value={price}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix="$"
              style={{
                textDecoration: discount ? "line-through" : "none",
                color: discount ? "gray" : "inherit",
                fontWeight: "300",
                fontFamily: "Nunito Sans, sans-serif",
              }}
            />{" "}
            <CurrencyFormat
              value={discountPrice}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix="$"
              style={{
                color: "#E21608",
                fontWeight: "600",
                fontFamily: "Nunito Sans, sans-serif",
              }}
            />{" "}
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontWeight: "900",
                fontFamily: "Nunito Sans, sans-serif",
                marginRight: "5px",
              }}
            >
              Precio:
            </div>
            <CurrencyFormat
              value={amount * (discount ? discountPrice : price)}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix="$"
              style={{
                fontWeight: "300",
                fontFamily: "Nunito Sans, sans-serif",
              }}
            />
          </div>
        </Box>
      </div>
    </div>
  );
}
