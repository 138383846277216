import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowUpward, ArrowDownward, Delete, Edit } from "@mui/icons-material";
import { useEffect } from "react";
import { deletePaper, editPaper, getAllPapers } from "utils/API/ArticulosAPI";
import { useHistory } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { isAdmin } from "utils/API/Login";

const SortableList = () => {
  const [items, setItems] = useState([]);
  let history = useHistory();

  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        const roleAdmin = await isAdmin(user);
        setAdmin(roleAdmin);
      }
    });

    async function fetchData() {
      const papers = await getAllPapers();
      papers.sort((a, b) => a.order - b.order);
      setItems(papers);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function updateOrders() {
      for (let i = 0; i < items.length; i++) {
        const uuid = items[i].id;
        await editPaper(uuid, {
          order: i,
        });
      }
    }

    updateOrders();
  }, [items]);

  const moveUp = (index) => {
    if (index > 0) {
      const updatedItems = [...items];
      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
      setItems(updatedItems);
    }
  };

  const moveDown = (index) => {
    if (index < items.length - 1) {
      const updatedItems = [...items];
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
      setItems(updatedItems);
    }
  };

  const deleteItem = async (index) => {
    const shouldDelete = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (shouldDelete) {
      await deletePaper(items[index].id);
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
    }
  };

  const editItem = (index) => {
    history.push(`/TipsEditar?id=${items[index].id}`);
  };

  if (user && admin) {
    return (
      <Box sx={{ maxWidth: 600, margin: "40px auto" }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Ordenar Artículos
          </Typography>
          <List>
            {items.map((item, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={item.title}
                  sx={{
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => moveUp(index)}
                    disabled={index === 0}
                  >
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={() => moveDown(index)}
                    disabled={index === items.length - 1}
                  >
                    <ArrowDownward />
                  </IconButton>
                  <IconButton edge="end" onClick={() => editItem(index)}>
                    <Edit />
                  </IconButton>
                  <IconButton edge="end" onClick={() => deleteItem(index)}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    );
  } else return null;
};

export default SortableList;
