import React from "react";
import { Container } from "@mui/material";
import ProductCard from "./ProductCard";

const ProductList = ({ products }) => {
  return (
    <Container sx={{ py: { xs: 1, md: 5 } }} maxWidth="lg">
      <h1 style={{
        fontFamily: "Nunito Sans, sans-serif",
        fontSize: "clamp(1rem, 5vw, 3rem)",
        fontWeight: "700",
        marginBottom: "10px",
        marginTop: "10px",
      }}>
        LOS MEJORES PRODUCTOS PARA EL MANTENIMIENTO DE LA TRANSMISIÓN AUTOMÁTICA
        DE TU AUTO
      </h1>
      <ProductCard products={products} />
    </Container>
  );
};

export default ProductList;
