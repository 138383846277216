import React from "react";

/* Material UI */
import Grid from "@material-ui/core/Grid";

/* Router */
import { useHistory } from "react-router-dom";

import ICONOS from "helpers/iconos";
import { COLORS } from "utils/Constants/Colors";

import Box from "@mui/material/Box";

function ProductSections() {
  /* Funcitons from routing */

  let history = useHistory();

  function handleClickRoute(route) {
    history.push(route);
  }

  const textSmall = {
    fontSize: "18px",
    fontWeight: "800",
    width: "30%",
    marginTop: "10px",
  };

  const textMobile = {
    fontSize: "18px",
    color: COLORS.back,
    fontWeight: "600",
  };

  return (
    <div>
      <h2
        style={{
          fontFamily: "Nunito Sans, sans-serif",
          fontSize: "clamp(1rem, 5vw, 3rem)",
          fontWeight: "700",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        CATEGORÍAS DESTACADAS PARA TU AUTO
      </h2>
      <Box
        component="div"
        sx={{ display: { xs: "none", md: "flex" } }}
        style={{
          margin: "5% 10%",
          height: "100%",
          widht: "100%",
        }}
      >
        {/* Primera Sección */}
        <div
          style={{
            width: "20%",
            marginRight: "5%",
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute("Categoria/kits-afinacion-transmision")}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={ICONOS.KAT}
              alt="Kit de Afinación de Transmisión"
              style={{ width: "50%" }}
            />
            <div style={{ ...textSmall, width: "50%" }}>
              {" "}
              Kit de Afinación de Transmisión{" "}
            </div>
          </div>
        </div>

        {/* Segunda Sección */}
        <div
          style={{
            width: "20%",
            marginRight: "5%",
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute("Categoria/aceites-afinazone")}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={ICONOS.Ac} alt="Aceite" style={{ width: "50%" }} />
            <div style={textSmall}> Aceite </div>
          </div>
        </div>

        {/* Tercera Sección */}
        <div
          style={{
            width: "20%",
            marginRight: "5%",
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute("Categoria/carters-motor-transmision-automatica-afinazone")}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={ICONOS.Cart} alt="Cárter" style={{ width: "50%" }} />
            <div style={textSmall}> Cárter </div>
          </div>
        </div>

        {/* Cuarta Sección */}
        <div
          style={{
            width: "20%",
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute("Categoria/enfriadores-aceite-afinazone")}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={ICONOS.Enf} alt="Enfriadores" style={{ width: "50%" }} />
            <div style={textSmall}> Enfriadores </div>
          </div>
        </div>
      </Box>

      <Box
        component="div"
        sx={{ display: { xs: "block", md: "none" } }}
        style={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <Grid
          container
          style={{
            paddingTop: "20px",
            marginBottom: "10px",
          }}
        >
          <Grid item xs={6}>
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: "10px",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                cursor: "pointer",
              }}
            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute("Categoria/kits-afinacion-transmision")}
              >
                <img
                  src={ICONOS.KAT}
                  alt="Kit de Afinación de Transmisión"
                  style={{ height: "75%", width: "75%" }}
                />
                <div style={textMobile}> Kit de Afinación de Transmisión</div>
              </div>
            </div>
          </Grid>

          <Grid item xs={6}>
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: "10px",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                cursor: "pointer",
              }}
            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute("Categoria/aceites-afinazone")}
              >
                <img
                  src={ICONOS.Ac}
                  alt="Cambio de Aceite para Motor"
                  style={{ height: "75%", width: "75%" }}
                />
                <div style={textMobile}> Aceites </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={6}>
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: "10px",
                paddingTop: "0px",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                cursor: "pointer",
              }}
            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute("Categoria/carters-motor-transmision-automatica-afinazone")}
              >
                <img
                  src={ICONOS.Cart}
                  alt="Cárter"
                  style={{ height: "75%", width: "75%" }}
                />
                <div style={textMobile}> Cárter </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={6}>
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: "10px",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                cursor: "pointer",
              }}
            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute("Categoria/enfriadores-aceite-afinazone")}
              >
                <img
                  src={ICONOS.Enf}
                  alt="Enfriadores"
                  style={{ height: "75%", width: "75%" }}
                />
                <div style={{ ...textMobile }}> Enfriadores </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ProductSections;
