import React, { useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

export default function Paypal({ items, createSellOrder }) {
  let paypalRef = useRef();
  let history = useHistory();

  useEffect(() => {
    let List = [];
    let total = 0;
    for (var item of items) {
      List.push({
        name: item.name,
        description: item.description,
        unit_amount: {
          currency_code: "MXN",
          value: parseFloat(
            item.discount ? item.discountPrice : item.price
          ).toFixed(2),
        },
        quantity: item.amount,
      });
      total += item.amount * (item.discount ? item.discountPrice : item.price);
    }
    if (total < 549.0) {
      total += 149.0;
      List.push({
        name: "Envío",
        description: item.description,
        unit_amount: {
          currency_code: "MXN",
          value: 149.0,
        },
        quantity: 1,
      });
    }

    console.log(total);

    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "Afinazone",
                amount: {
                  currency_code: "MXN",
                  value: parseFloat(total).toFixed(2),
                  breakdown: {
                    item_total: {
                      currency_code: "MXN",
                      value: parseFloat(total).toFixed(2),
                    },
                  },
                },
                items: List,
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log(order, data);
          localStorage.setItem("carrito", "");
          history.push("/");
          createSellOrder();
        },
        onError: (err) => {
          console.log(err);
          alert("Transaccion NO Exitosa");
        },
      })
      .render(paypalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div ref={paypalRef}></div>
    </div>
  );
}
