import { Box, Button, Divider, Typography } from "@mui/material";
import Gallery from "components/Gallery/Gallery";

export default function TipsSection({ cols }) {
  return (
    <Box
      component="div"
      sx={{ py: { xs: 3, md: 15 } }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2" // Cambia a "h2" para el título
        component="h2"
        align="center"
        sx={{
          fontFamily: "Nunito Sans, sans-serif",
          fontSize: { xs: "1.5rem", md: "3.75rem" },
          fontWeight: "700",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        TIPS PARA TU VEHÍCULO
      </Typography>
      <Divider
        sx={{
          my: { sx: 4, md: 2 },
          borderBottomWidth: 2,
          bgcolor: "black",
          width: "80%",
        }}
      />
      <Gallery />
      <Button variant="outlined" onClick={() => window.open("/tips-mantenimiento-auto")}>
        Ver Más Tips
      </Button>
    </Box>
  );
}
