import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, useMediaQuery } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleRegister = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const db = getFirestore();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        email
      });

      setSuccess(true);
      // Limpiar el formulario
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setError(null);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  const titleStyle = {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: isXs ? "30px" : "70px",
    fontWeight: "700",
    marginBottom: "10px",
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100vh"
      >
        <div style={titleStyle}>
          Registrarse  
        </div>
        <form onSubmit={handleRegister}>
          <TextField
            label="Nombre"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Apellido"
            fullWidth
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            label="Correo electrónico"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Contraseña"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          {success && (
            <Typography color="primary" variant="body2">
              Registro exitoso. Ahora puedes iniciar sesión.
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 16 }}
          >
            Registrarse
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Register;
