import { Button } from "@mui/material";
import { PRODUCT } from "helpers/helpers"; // Assumes PRODUCT contains product data
import { useEffect, useState } from "react";
import { getDiscounts, saveDiscounts } from "utils/API/DescuentosAPI";

const container = {
  margin: "30px auto",
  padding: "20px",
  backgroundColor: "white",
  width: "fit-content",
};

const row = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: "20px",
  marginTop: "10px",
  borderBottom: "1px solid black",
  paddingBottom: "10px",
};

const product = {
  marginRight: "20px",
  marginBottom: "10px",
};

const inputStyle = {
  width: "100px",
  padding: "5px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  textAlign: "center",
};

export default function Descuentos() {
  const [discounts, setDiscounts] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedDiscounts = await getDiscounts();
        setDiscounts(fetchedDiscounts);
      } catch (error) {
        console.error("Error al obtener los descuentos:", error);
      }
    };

    fetchData();
  }, []);

  // Handle input change with strict validation
  const handleInputChange = (event, item) => {
    const value = event.target.value;

    // Allow empty input or valid numbers with up to 2 decimals
    if (
      value === "" ||
      (/^\d*\.?\d{0,2}$/.test(value) && parseFloat(value) <= 100)
    ) {
      setDiscounts((prev) => ({
        ...prev,
        [item]: value, // Temporarily store the raw input
      }));
    }
  };

  return (
    <div style={container}>
      <h3>Descuentos por categoría</h3>
      <div>
        {Object.keys(PRODUCT).map((item, index) => (
          <div style={row} key={index}>
            <div style={product}>{PRODUCT[item]}</div>
            <input
              type="text"
              style={inputStyle}
              placeholder="Enter %"
              value={discounts[item] || ""}
              onChange={(e) => handleInputChange(e, item)}
            />
          </div>
        ))}
      </div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: 16 }}
        onClick={() => saveDiscounts(discounts)}
      >
        Actualizar
      </Button>
    </div>
  );
}
