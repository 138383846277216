import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./latido.css";
import CurrencyFormat from "react-currency-format";

/*helpers*/
import {
  useQuery,
  convertToShoppingCartElement,
  getDiscountsInProduct,
} from "helpers/helpers";

import { getProductAPI } from "utils/API/Product";
import { getPreviewsImagesUrlFromProduct } from "utils/API/Pictures";

/* Components */
import Paypal from "../../components/PayPal";
import { Button, Card } from "@material-ui/core";

/* Sections */
import PayInfo from "./Sections/PayInfo";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

/* Icons */
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import protectedShopping from "assets/img/compraProtegida.png";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { addNewSell } from "utils/API/VentasAPI";
import {
  sendClientEmail,
  sendAfinazoneEmail,
} from "utils/EmailSender/EmailSender";
import { Divider, Typography } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const whatsapp_float = {
  backgroundColor: "#25d366",
  padding: "15px",
  color: "#FFF",
  borderRadius: "50px",
  fontSize: "28px",
  boxShadow: "2px 2px 3px #999",
  marginRight: "10px",
  display: "flex",
};

const paybutton = {
  width: "40%",
};
const paybuttonMobile = {
  width: "100%",
};

const payinfo = {
  width: "60%",
};

const payinfoMobile = {
  width: "100%",
};

const labelStyle = {
  fontSize: "20px",
};

const fieldStyle = {
  marginBottom: "20px",
  maxWidth: "500px",
};

const text = {
  fontFamily: "Poppins, sans-serif",
};

function Producto() {
  const [purchase, setPurchase] = useState(null);

  let query = useQuery();

  const [selected, setSelected] = React.useState(0);

  const [data, setData] = useState(null);
  const [dataURL, setDataURL] = useState(null);
  const [pay, setPay] = useState(2);

  /* Formulario */

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [colony, setColony] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [addressReference, setAddressReference] = useState("");
  const [phone, setPhone] = useState("");

  const createSellOrder = async () => {
    function formatDateToDDMMYYYYHHMM() {
      const date = new Date();

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    const date = formatDateToDDMMYYYYHHMM();

    const products =
      purchase !== null
        ? purchase.map((item) => {
            return {
              quantity: item.amount,
              description: item.description,
              name: item.name,
              type: item.type,
              uuid: item.id,
              price: item.price,
            };
          })
        : [];

    const ok = await addNewSell({
      realDate: new Date(),
      date,
      name,
      email,
      street,
      postalCode,
      colony,
      city,
      state,
      country,
      phone,
      referenceAddress: addressReference,
      status: "pendiente",
      products,
    });

    if (ok) {
      alert("orden creada correctamente");

      const clientData = {
        name,
        street,
        reference: addressReference,
        neighbor: colony,
        postal_code: postalCode,
        city,
        state,
        country,
        phone,
        email,
      };
      await sendClientEmail(clientData, products);
      var emailStatus = await sendAfinazoneEmail(clientData, products);

      if (emailStatus.success) {
        alert("email enviado correctamente, revisar spam");
      } else {
        alert("no pudo ser enviado el email");
        console.log(emailStatus);
      }
    } else {
      alert(
        "orden pagada pero no creada, contactar a soporte para envío correcto de su orden"
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    if (phone.length < 10) {
      alert("telefono incorrecto");
      return;
    } else if (!name) {
      alert("indica algún nombre");
      return;
    } else if (!street) {
      alert("indica alguna calle");
      return;
    } else if (!postalCode) {
      alert("indica algún código postal");
      return;
    } else if (!colony) {
      alert("indica alguna colonia");
      return;
    } else if (!city) {
      alert("indica alguna ciudad");
      return;
    } else if (!state) {
      alert("indica algún estado");
      return;
    } else if (!country) {
      alert("indica algún país");
      return;
    } else if (!addressReference) {
      alert("indica alguna referencia de domicilio");
      return;
    } else if (!email || !validateEmail(email)) {
      alert("indica un email válido");
      return;
    }
    setPay(2);
  };

  const validatePhone = (phone) => {
    if (phone.length > 10) return;
    const validNumber = new RegExp("^[0-9]*$");

    if (validNumber.test(phone)) setPhone(phone);
  };

  function buyProduct(price, description) {
    var newItem = {
      id: data.id,
      type: data.type,
      description: description ? description + " litros" : "",
      price: price,
      amount: 1,
      name: data.name,
      url: data.url,
    };
    console.log(data, newItem);
    setPay(1);
    setPurchase([newItem]);
  }

  function addToShoppingCart(data, type, description) {
    function getItem() {
      var newItem = {
        id: data.id,
        type: type,
        description: description ? description + " litros" : "",
        price: data.price,
        amount: 1,
        name: data.name,
      };
      newItem = JSON.stringify(newItem);
      return newItem;
    }

    const shoppingCart = convertToShoppingCartElement();
    if (shoppingCart) {
      if (
        !shoppingCart.filter((item) => {
          return (
            item.id === data.id &&
            (description ? item.description === description + " litros" : true)
          );
        }).length
      ) {
        const newItem = getItem();
        let newShoppingCart = [
          ...shoppingCart.map((item) => JSON.stringify(item)),
          newItem,
        ];
        newShoppingCart = newShoppingCart.join("+|+");
        localStorage.setItem("carrito", newShoppingCart);
        alert("Se agregó al carrito");
      } else alert("Este elemento ya está en tu carrito");
    } else {
      const newItem = getItem();
      let newShoppingCart = [newItem];
      newShoppingCart = newShoppingCart.join("+|+");
      localStorage.setItem("carrito", newShoppingCart);
      alert("Se agregó al carrito");
    }
  }

  useEffect(() => {
    async function go() {
      let product = await getProductAPI(query.get("id"), query.get("product"));
      let picturesURL = await getPreviewsImagesUrlFromProduct(
        query.get("id"),
        query.get("product")
      );
      product.type = query.get("product");
      await getDiscountsInProduct(product);
      setData({
        ...product,
        id: query.get("id"),
        type: query.get("product"),
        url: picturesURL.length > 0 ? picturesURL[0] : undefined,
      });
      setDataURL(picturesURL);
    }
    go();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (purchase === null) {
    return (
      <>
        {data && (
          <Box
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <PictureSelector
                picturesURL={dataURL}
                handleSelector={(index) => setSelected(index)}
              />
              <Box
                sx={{
                  width: { xs: "100%", md: "750px" },
                  display: { xs: "block", md: "flex" },
                }}
                component="div"
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component="h2"
                  sx={{
                    display: { xs: "block", md: "none" },
                    fontSize: { xs: "15px" },
                  }}
                >
                  {data?.name}
                </Box>
                {dataURL !== null && (
                  <Box
                    component="img"
                    src={dataURL[selected]}
                    alt="car"
                    sx={{
                      width: { xs: "100%", md: "500px" },
                      height: { xs: "250px", md: "500px" },
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                  style={{ fontSize: "13px" }}
                >
                  {selected + 1} / {dataURL?.length}
                </Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "flex" },
                    width: { xs: "100%", md: "500px" },
                    fontSize: { xs: "12px", md: "15px" },
                  }}
                  component={Card}
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      padding: "20px",
                      ...text,
                    }}
                  >
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    >
                      {data.description}
                    </ReactMarkdown>
                  </div>
                </Box>
                CÓDIGO UPC: {data?.upcCode}
              </Box>

              <Box
                component="div"
                sx={{
                  display: { xs: "none", md: "flex" },
                }}
                style={{
                  height: "100%",
                  width: "35%",
                  backgroundColor: "white",
                }}
              >
                <ProductInfo
                  data={data}
                  setPurchase={setPurchase}
                  setPay={setPay}
                />
              </Box>
            </div>
          </Box>
        )}
        {data && (
          <>
            <Box
              component="div"
              sx={{ display: { xs: "block", sm: "none" } }}
              style={{
                padding: "5px",
                backgroundColor: "white",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                {dataURL !== null && (
                  <img
                    src={dataURL[selected]}
                    alt="car"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "250px",
                    }}
                  />
                )}
                <PictureSelector
                  picturesURL={dataURL}
                  handleSelector={(index) => setSelected(index)}
                />
              </div>
              <div>
                <div
                  style={{
                    textAlign: "center", // Center the title text
                    fontWeight: "900",
                    fontFamily: "Nunito Sans, sans-serif",
                  }}
                >
                  {data.name}
                </div>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize="13px"
                  fontWeight="700"
                >
                  {" "}
                  {/* Change to align center */}
                  <Box component="span" sx={{ color: "primary.main" }}>
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize="13px"
                  fontWeight="800"
                  style={{
                    textDecoration: "underline",
                    marginTop: "5px",
                    marginBottom: "5px",
                    letterSpacing: "4px", // Espaciado entre letras
                    paddingBottom: "3px",
                  }}
                >
                  Lista de precios
                </Typography>
                {data.disableOil && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      {/* Div centrado con información de precio y botón "COMPRAR AHORA" */}
                      <div
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "5px",
                              fontFamily: "Nunito Sans, sans-serif",
                            }}
                          >
                            Precio{" "}
                            <CurrencyFormat
                              value={data.price}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType={"text"}
                              prefix={"$ "} // Agrega el símbolo de $
                              style={{
                                fontSize: "16px", // Ajusta el tamaño de la fuente
                                fontWeight: "900",
                                fontFamily: "Nunito Sans, sans-serif",
                              }}
                            />
                          </div>
                        </div>

                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "10px",
                            padding: "4px 8px",
                          }}
                          onClick={() => {
                            buyProduct(data.price, null);
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "900",
                              fontFamily: "Nunito Sans, sans-serif",
                              textTransform: "none",
                            }}
                          >
                            COMPRAR AHORA
                          </Typography>
                        </Button>
                      </div>

                      {/* Botón de carrito alineado a la derecha */}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginLeft: "auto", // Empuja el botón de carrito a la derecha
                          marginTop: "auto", // Empuja el botón de carrito a la derecha
                          padding: "4px 4px",
                        }}
                        onClick={() => addToShoppingCart(data, "KAT", null)}
                      >
                        <AddShoppingCartIcon style={{ fontSize: "20px" }} />
                      </Button>
                    </div>
                    <Divider
                      style={{ width: "90vw", borderBottomWidth: "3px" }}
                    />
                  </>
                )}
                {!data.disableOil && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Center items
                    }}
                  >
                    {data.liters.map((current, idx) => {
                      return (
                        <div
                          key={idx} // Added key for mapping
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center", // Center vertically
                            alignItems: "center", // Center horizontally
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                          >
                            {/* Div centrado con información de precio y botón "COMPRAR AHORA" */}
                            <div
                              style={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "5px",
                                    fontFamily: "Nunito Sans, sans-serif",
                                  }}
                                >
                                  Precio{" "}
                                  <CurrencyFormat
                                    value={current.price}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                    prefix={"$ "} // Agrega el símbolo de $
                                    style={{
                                      fontSize: "16px", // Ajusta el tamaño de la fuente
                                      fontWeight: "900",
                                      fontFamily: "Nunito Sans, sans-serif",
                                    }}
                                  />
                                </div>
                                <div style={{ fontSize: "10px" }}>
                                  {current.liters} Litros
                                </div>
                              </div>

                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  marginTop: "10px",
                                  padding: "4px 8px",
                                }}
                                onClick={() => {
                                  buyProduct(
                                    current.price,
                                    current.description
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "900",
                                    fontFamily: "Nunito Sans, sans-serif",
                                    textTransform: "none",
                                  }}
                                >
                                  COMPRAR AHORA
                                </Typography>
                              </Button>
                            </div>

                            {/* Botón de carrito alineado a la derecha */}
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                marginLeft: "auto", // Empuja el botón de carrito a la derecha
                                marginTop: "auto", // Empuja el botón de carrito a la derecha
                                padding: "4px 4px",
                              }}
                              onClick={() =>
                                addToShoppingCart(
                                  { ...data, price: current.price },
                                  "KAT",
                                  current.liters
                                )
                              }
                            >
                              <AddShoppingCartIcon
                                style={{ fontSize: "20px" }}
                              />
                            </Button>
                          </div>

                          <Divider
                            style={{ width: "80vw", borderBottomWidth: "3px" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}

                <div
                  style={{
                    paddingLeft: "15%",
                    paddingRight: "15%",
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={protectedShopping}
                    alt="compra protegida"
                    style={{ height: "30px", width: "30px", margin: "10px" }}
                  />
                  <Link
                    to="/envios-y-pagos"
                    style={{ color: "blue", textDecoration: "none" }}
                  >
                    Compra protegida
                  </Link>
                </div>
                <div
                  style={{
                    padding: "15px",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      paddingBottom: "10px",
                    }}
                  >
                    El tiempo de entrega del producto estimado es de 2 a 5 días
                    hábiles, este puede variar dependiendo el domicilio
                  </div>
                  <div
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      paddingBottom: "10px",
                    }}
                  >
                    Si desea factura favor de enviar sus datos fiscales al
                    correo <b>afinazone@outlook.com</b>
                  </div>
                  <div
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      paddingBottom: "10px",
                    }}
                  >
                    Para más detalles{" "}
                    <Link
                      to="/envios-y-pagos"
                      style={{ color: "#E21608", textDecoration: "none" }}
                    >
                      click aquí
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            <Box
              component="div"
              sx={{ display: { xs: "block", sm: "none" } }}
              style={{
                padding: "5px",
                backgroundColor: "white",
                margin: "10px",
              }}
            >
              <Typography
                variant="body1"
                align="center"
                fontSize="16px"
                fontWeight="800"
                style={{
                  textDecoration: "underline",
                  marginTop: "10px",
                }}
              >
                DESCRIPCIÓN
              </Typography>
              <div
                style={{
                  textAlign: "left",
                  padding: "0 10px 10px 10px",
                  ...text,
                }}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {data.description}
                </ReactMarkdown>
              </div>
            </Box>
            <div style={{ fontSize: "13px", margin: "20px" }}>
              CÓDIGO UPC: {data.upcCode}
            </div>

            <Box
              component="div"
              sx={{ display: { xs: "block", sm: "none" } }}
              style={{
                padding: "5px",
                backgroundColor: "white",
                margin: "10px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "Nunito Sans, sans-serif",
                }}
              >
                ¿TIENES DUDAS SOBRE EL ENVÍO, LA COMPATIBILIDAD O DISPONIBILIDAD
                DEL PRODUCTO?
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <a
                    href="https://wa.me/524613126256 "
                    style={whatsapp_float}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WhatsAppIcon style={{ height: "30px", width: "30px" }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "12px",
                        }}
                      >
                        Atención a clientes
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          textDecoration: "none",
                          fontSize: "20px",
                        }}
                      >
                        461-312-6256
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Box>
          </>
        )}
      </>
    );
  } else if (pay === 1) {
    return (
      <Box
        sx={{
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: { xs: "30px", sm: "100px" },
          fontSize: { xs: "22px", sm: "40px" },
          textAlign: "center",
          fontWeight: { xs: "700", sm: "500" },
          fontFamily: "Nunito Sans, sans-serif",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          component={ArrowBackIcon}
          sx={{
            fontSize: { xs: "25px", sm: "40px" },
            fontWeight: "900",
            cursor: "pointer",
            position: "absolute",
            left: "10%", // Posición relativa al contenedor
            top: { xs: "32px", sm: "110px" },
          }}
          onClick={() => setPurchase(null)}
        />
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "10px",
            fontFamily: "inherit",
            fontSize: "inherit",
          }}
        >
          Información de envío
        </Box>
        <hr style={{ width: "100%", borderColor: "#ccc" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: "100%", // Asegura que el formulario ocupe el 100% del ancho disponible
          }}
        >
          <p style={labelStyle}> Nombre </p>
          <TextField
            id="referencia-name"
            placeholder="Nombre de quién recibe el producto"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="name"
          />

          <p style={labelStyle}> Correo Electrónico </p>
          <TextField
            id="referencia-email"
            placeholder="Email"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <p style={labelStyle}> Número de Teléfono</p>
          <TextField
            id="referencia-phone"
            placeholder="Número telefónico"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={phone}
            onChange={(e) => validatePhone(e.target.value)}
            name="phone"
            autoComplete="phone"
          />

          <p style={labelStyle}> Calle y número </p>
          <TextField
            id="referencia-street"
            placeholder="EJ: PASEO DE LA REFORMA #163B"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />

          <p style={labelStyle}> Código postal </p>
          <TextField
            id="referencia-postalCode"
            placeholder="EJ: 066600"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />

          <p style={labelStyle}> Colonia </p>
          <TextField
            id="referencia-colony"
            placeholder="EJ: LINDAVISTA"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={colony}
            onChange={(e) => setColony(e.target.value)}
            name="address2"
            autoComplete="address-level2"
          />

          <p style={labelStyle}> Ciudad </p>
          <TextField
            id="referencia-city"
            placeholder="EJ: Monterrey"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />

          <p style={labelStyle}> Estado </p>
          <TextField
            id="referencia-state"
            placeholder="EJ: Nuevo León"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={state}
            onChange={(e) => setState(e.target.value)}
          />

          <p style={labelStyle}> País </p>
          <TextField
            id="country"
            name="country"
            placeholder="EJ: México"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            autoComplete="country"
          />

          <p style={labelStyle}> Referencia de domicilio</p>
          <TextField
            id="referencia-domicilio"
            placeholder="Referencia del domicilio a ingresar"
            multiline
            variant="filled"
            fullWidth
            rows={4}
            onChange={(e) => setAddressReference(e.target.value)}
            style={fieldStyle}
            value={addressReference}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <Button
              style={{
                width: window.innerWidth < 600 ? "150px" : "300px",
                marginTop: window.innerWidth < 600 ? "20px" : "30px",
                marginBottom: window.innerWidth < 600 ? "20px" : "30px",
                height: window.innerWidth < 600 ? "30px" : "50px",
              }}
              color="primary"
              variant="contained"
              onClick={() => {
                validateFields();
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "11px", sm: "20px" },
                  fontWeight: "900",
                  fontFamily: "Nunito Sans, sans-serif",
                  textTransform: "none",
                }}
              >
                PROCEDER AL PAGO
              </Typography>
            </Button>
          </Box>
        </div>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: { xs: "30px", sm: "100px" },
          fontSize: { xs: "22px", sm: "40px" },
          textAlign: "center",
          fontWeight: { xs: "700", sm: "500" },
          fontFamily: "Nunito Sans, sans-serif",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          component={ArrowBackIcon}
          sx={{
            fontSize: { xs: "25px", sm: "40px" },
            fontWeight: "900",
            cursor: "pointer",
            position: "absolute",
            left: "10%", // Posición relativa al contenedor
            top: { xs: "32px", sm: "110px" },
          }}
          onClick={() => setPurchase(null)}
        />
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "10px",
            fontFamily: "inherit",
            fontSize: "inherit",
          }}
        >
          Finalizar Compra
        </Box>
        <hr style={{ width: "100%", borderColor: "#ccc" }} />
        <Box component="div" sx={{ display: { xs: "none", md: "flex" } }}>
          <div style={paybutton}>
            <Paypal items={purchase} createSellOrder={createSellOrder} />
          </div>
          <div style={payinfo}>
            <PayInfo items={purchase} price={purchase[0].price} />
          </div>
        </Box>
        <Box component="div" sx={{ display: { xs: "block", md: "none" } }}>
          <div style={payinfoMobile}>
            <PayInfo items={purchase} price={purchase[0].price} />
          </div>
          <div style={paybuttonMobile}>
            <Paypal items={purchase} createSellOrder={createSellOrder} />
          </div>
        </Box>
      </Box>
    );
  }
}

export default Producto;

function PictureSelector({ picturesURL, handleSelector }) {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        borderRight: { md: "1px solid #797979" },
        marginBottom: { xs: "3px", md: "10px" },
      }}
      style={{
        height: "100%",
        flexDirection: "column",
        alignItems: "flex-end",
        marginLeft: "10px",
      }}
    >
      {picturesURL !== null &&
        picturesURL.map((item, index) => {
          return (
            <Box
              component="img"
              src={item}
              onClick={() => handleSelector(index)}
              alt="car"
              sx={{
                height: { xs: "60px", md: "100px" },
                width: { xs: "60px", md: "100px" },
                marginBottom: "5px",
                border: "2px solid #f2f2f2",
                cursor: "pointer",
              }}
            />
          );
        })}
    </Box>
  );
}

function ProductInfo({ data, setPurchase, setPay }) {
  if (data === null) {
    return null;
  }

  function buyProduct(price, description) {
    var newItem = {
      id: data.id,
      type: data.type,
      description: description ? description + " litros" : "",
      price: price,
      amount: 1,
      name: data.name,
      url: data.url,
    };
    setPay(1);
    setPurchase([newItem]);
  }

  function addToShoppingCart(price, description) {
    function getItem() {
      var newItem = {
        id: data.id,
        type: data.type,
        description: description ? description + " litros" : "",
        price: price,
        amount: 1,
        name: data.name,
      };
      newItem = JSON.stringify(newItem);
      return newItem;
    }

    const shoppingCart = convertToShoppingCartElement();
    if (shoppingCart) {
      if (
        !shoppingCart.filter((item) => {
          return (
            item.id === data.id &&
            (description ? item.description === description + " litros" : true)
          );
        }).length
      ) {
        const newItem = getItem();
        let newShoppingCart = [
          ...shoppingCart.map((item) => JSON.stringify(item)),
          newItem,
        ];
        newShoppingCart = newShoppingCart.join("+|+");
        localStorage.setItem("carrito", newShoppingCart);
        alert("Se agregó al carrito");
      } else alert("Este elemento ya está en tu carrito");
    } else {
      const newItem = getItem();
      let newShoppingCart = [newItem];
      newShoppingCart = newShoppingCart.join("+|+");
      localStorage.setItem("carrito", newShoppingCart);
      alert("Se agregó al carrito");
    }
  }

  const rowMini = {
    paddingLeft: "15%",
    paddingRight: "15%",
    marginBottom: "20px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "left",
  };

  const infoTitle = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "300",
    fontSize: "35px",
  };

  return (
    <div style={{ padding: "10px" }}>
      <Box
        component="h2"
        sx={{ display: { xs: "none", md: "block" } }}
        style={{ ...infoTitle, fontStyle: "italic" }}
      >
        {data.name}
      </Box>

      {data.disableOil && (
        <div>
          <p style={{ fontSize: "45px", fontFamily: "Nunito, sans-serif" }}>
            {data.discount && (
              <>
                <CurrencyFormat
                  prefix="$"
                  value={data.price}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  style={{
                    fontFamily: "Nunito, sans-serif",
                    textDecoration: "line-through",
                    color: "gray",
                    marginRight: "10px",
                  }}
                />
                <CurrencyFormat
                  prefix="$"
                  value={data.discountPrice}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  style={{
                    fontFamily: "Nunito, sans-serif",
                    color: "#E21608",
                  }}
                />
              </>
            )}{" "}
            {!data.discount && (
                <CurrencyFormat
                  prefix="$"
                  value={data.price}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  style={{
                    fontFamily: "Nunito, sans-serif",
                    marginRight: "10px",
                  }}
                />
            )}{" "}
          </p>
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: "10px",
              borderRadius: "20px",
              fontFamily: "Nunito, sans-serif",
              fontStyle: "italic",
              fontWeight: "900",
            }}
            onClick={() => buyProduct(data.price, null)}
          >
            {" "}
            comprar Ahora{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: "10px",
              borderRadius: "20px",
              fontFamily: "Poppins, sans-serif",
              fontStyle: "italic",
              fontWeight: "1000",
            }}
            onClick={() => addToShoppingCart(data.price, null)}
          >
            Agregar al carrito
          </Button>
        </div>
      )}
      {!data.disableOil && (
        <div>
          {data.liters.map((item) => {
            return (
              <div>
                <p style={{ fontSize: "25px" }}>
                  {data.discount && (
                    <>
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        prefix="$"
                        style={{
                          textDecoration: "line-through",
                          color: "gray",
                          marginRight: "10px",
                        }}
                      />
                      <CurrencyFormat
                        prefix="$"
                        value={item.discountPrice}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        style={{ color: "#E21608" }}
                      />
                    </>
                  )}
                  {!data.discount && (
                    <CurrencyFormat
                      value={item.price}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      prefix="$"
                    />
                  )}
                  , {item.liters} litros
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px" }}
                  onClick={() => buyProduct(item.price, item.liters)}
                >
                  {" "}
                  comprar Ahora{" "}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "10px" }}
                  onClick={() => addToShoppingCart(item.price, item.liters)}
                >
                  Agregar al carrito
                </Button>
              </div>
            );
          })}
        </div>
      )}

      <Typography variant="body3" align="center">
        <Box component="span" color="error.main">
          envío GRATIS
        </Box>
        <Box component="span">&nbsp;apartar de $549 en tu carrito</Box>
      </Typography>

      <div
        style={{
          paddingLeft: "15%",
          paddingRight: "15%",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={protectedShopping}
          alt="compra protegida"
          style={{ height: "30px", width: "30px", margin: "10px" }}
        />
        <Link
          to="/envios-y-pagos"
          style={{ color: "blue", textDecoration: "none" }}
        >
          Compra protegida
        </Link>
      </div>

      <div style={rowMini}>
        El tiempo de entrega del producto estimado es de 2 a 5 días hábiles,
        este puede variar dependiendo el domicilio
      </div>

      <div style={rowMini}>
        Si desea factura favor de enviar sus datos fiscales al correo{" "}
        <b>afinazone@outlook.com</b>
      </div>

      <div style={{ ...rowMini, textAlign: "center" }}>
        ¿TIENES DUDAS SOBRE EL ENVÍO, LA COMPATIBILIDAD O DISPONIBILIDAD DEL
        PRODUCTO?
      </div>

      <div style={{ ...rowMini, display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <a
            href="https://wa.me/524613126256 "
            style={whatsapp_float}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon style={{ height: "50px", width: "50px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ fontFamily: "Poppins, sans-serif", fontSize: "15px" }}
              >
                Atención a clientes
              </div>
              <div
                style={{
                  fontFamily: "Poppins, sans-serif",
                  textDecoration: "none",
                  fontSize: "25px",
                }}
              >
                461-312-6256
              </div>
            </div>
          </a>
        </div>
      </div>

      <div style={{ ...rowMini, textAlign: "center" }}>
        Para más detalles{" "}
        <Link
          to="/envios-y-pagos"
          style={{ color: "#E21608", textDecoration: "none" }}
        >
          click aquí
        </Link>
      </div>
    </div>
  );
}
