import { useState, useEffect } from "react";

/* Material UI */
import { Divider, useMediaQuery } from "@material-ui/core";

/* Icons */
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Box from "@mui/material/Box";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { SLUG } from "helpers/helpers";

export default function LabelSelector(props) {
  const { typeSlug } = useParams();

  const [type, setType] = useState(null);
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs")); // Verifica si es pantalla extra pequeña

  useEffect(() => {
    const productType = SLUG[typeSlug];
    setType(productType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="div"
      sx={{
        fontSize: { xs: "15px", md: "50px" },
        fontWeight: "500",
        paddingBottom: { xs: '15px', md: "30px" },
        paddingTop: { xs: '15px', md: "30px" },
        paddingLeft: "8px",
        paddingRight: "8px",
        fontFamily: "Nunito Sans, sans-serif",
      }}
    >
      {/* Section to select a car */}
      {props.optionSection === "car" &&
        (isXs ? "Selecciona la marca" : "Por favor, selecciona la marca de tu auto")}
      {props.optionSection === "typeOfOil" &&
        (isXs ? "Selecciona el tipo de aceite" : "Por favor, selecciona el tipo de aceite")}
      {/* Section to select a model */}
      {props.optionSection === "model" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <span>
            {!props.hideArrow && (
              <ArrowBackIcon
                style={{
                  color: "black",
                  fontSize: "inherit",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setOptionSection("car");
                  localStorage.removeItem("car");
                }}
              />
            )}
            {isXs ? "Selecciona el modelo" : "Por favor selecciona el modelo de tu auto"}
          </span>
        </div>
      )}
      {props.optionSection === "brandOil" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <span>
            {!props.hideArrow && (
              <ArrowBackIcon
                style={{
                  color: "black",
                  fontSize: "inherit",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setOptionSection("typeOfOil");
                  localStorage.removeItem("brandOil");
                }}
              />
            )}
            {isXs ? "Selecciona la marca de aceite" : "Por favor selecciona la marca de aceite"}
          </span>
        </div>
      )}
      {/* Section of products */}
      {props.optionSection === "product" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <span>
            {!props.hideArrow && (
              <ArrowBackIcon
                style={{
                  color: "black",
                  fontSize: "inherit",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (type !== "Ac") {
                    props.setOptionSection("model");
                    localStorage.removeItem("model");
                  } else {
                    props.setOptionSection("brandOil");
                    localStorage.removeItem("brandOil");
                  }
                }}
              />
            )}
            {isXs ? "¡Listo! Explora los productos que te recomendamos para tu auto" : "¡Tenemos los siguientes productos para ti!"}
          </span>
        </div>
      )}
      {props.optionSection === "product" && (
        <Divider
          style={{
            marginTop: "10px",
            backgroundColor: "black",
            height: "1px",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        />
      )}
    </Box>
  );
}
