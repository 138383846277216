import { doc, setDoc, getDoc } from "firebase/firestore"; 
import { db } from "ConfigFirebase";

export const saveDiscounts = async (discounts) => {
  const docRef = doc(db, "descuentos", "Gx2LiF65TXATQ2umdcjn"); // ID del documento en Firestore
  
  try {
    // Crear un objeto con los descuentos en el formato adecuado
    const discountData = {};
    for (const [key, value] of Object.entries(discounts)) {
      // Asumimos que los descuentos son valores numéricos
      discountData[key] = parseFloat(value);
    }
    
    // Actualizar el documento con los descuentos
    await setDoc(docRef, discountData, { merge: true });
    alert("Descuentos guardados exitosamente");
  } catch (error) {
    console.error("Error al guardar los descuentos:", error);
    alert("Hubo un problema al guardar los descuentos");
  }
};


export const getDiscounts = async () => {
  const docRef = doc(db, "descuentos", "Gx2LiF65TXATQ2umdcjn"); // ID del documento en Firestore
  
  try {
    // Obtener el documento
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // El documento existe, obtener los datos
      const data = docSnap.data();

      // Aquí puedes retornar los descuentos o hacer algo con ellos
      return data;
    } else {
      console.log("No existe el documento");
      return null;
    }
  } catch (error) {
    console.error("Error al obtener los descuentos:", error);
    alert("Hubo un problema al obtener los descuentos");
    return null;
  }
};
