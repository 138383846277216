/* Icons */
import { Box } from "@material-ui/core";
import ship from "assets/img/ship.png";
import CurrencyFormat from "react-currency-format";

const container = {
  paddingLeft: "15px",
  marginBottom: "15px",
};

const card = {
  width: "100%",
  padding: "15px",
  backgroundColor: "white",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "300",
};

const head = {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
  paddingBottom: "10px",
  fontWeight: "600",
  fontFamily: "Poppins, sans-serif",
};

const row = {
  display: "flex",
  margin: "5px",
  paddingBottom: "10px",
  fontFamily: "Poppins, sans-serif",
};

const nombre = {
  width: "50%",
  fontWeight: "inherit",
  fontFamily: "inherit",
  textAlign: "left",
};

const precioUnitario = {
  width: "25%",
  fontWeight: "inherit",
  fontFamily: "inherit",
};

const cantidad = {
  width: "10%",
  fontWeight: "inherit",
  fontFamily: "inherit",
};

const totalArticulo = {
  width: "15%",
  fontWeight: "inherit",
  fontFamily: "inherit",
};

const column = {
  display: "flex",
  flexDirection: "column",
  margin: "5px",
};

const rowMiniMobile = {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
  fontSize: "12px",
};

const rowMini = {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  textAlign: "left",
};

const inheritFont = {
  fontFamily: "inherit",
  fontSize: "inherit",
};

const border = {
  height: "2px", // Thicker line
  backgroundColor: "gray",
  border: "none",
  margin: "10px 0", // Add spacing around the line
};

const productBorder = {
  height: "1px", // Thinner line
  backgroundColor: "gray",
  border: "none",
  margin: "10px 0", // Add spacing around the line
};

const strongText = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: "600",
};

export default function PayInfo({ items, price, saving }) {
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }} style={container}>
        <div style={card}>
          <div style={head}>
            <div style={nombre}> Articulo </div>
            <div style={precioUnitario}> Precio Unit. </div>
            <div style={cantidad}> Cant. </div>
            <div style={totalArticulo}> Precio </div>
          </div>
          <hr style={border} />
          {items.map((item) => (
            <>
              <div style={row}>
                <div style={{ ...nombre, fontSize: "12px", fontWeight: "400" }}>
                  {" "}
                  {item.name}{" "}
                  {item.description !== "" ? "(" + item.description + ")" : ""}{" "}
                </div>
                <div style={precioUnitario}>
                  <CurrencyFormat
                    value={item.price}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix="$"
                    style={{
                      textDecoration: item.discount ? "line-through" : "none",
                      color: item.discount ? "gray" : "inherit",
                      fontWeight: "300",
                      fontFamily: "Nunito Sans, sans-serif",
                    }}
                  />{" "}
                  {item.discount && (
                    <CurrencyFormat
                      value={item.discountPrice}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      prefix="$"
                      style={{
                        color: "#E21608",
                        fontWeight: "600",
                        fontFamily: "Nunito Sans, sans-serif",
                      }}
                    />
                  )}
                </div>
                <div style={cantidad}> {item.amount} </div>
                <div style={totalArticulo}>
                  <CurrencyFormat
                    value={Number(
                      (
                        (item.discount ? item.discountPrice : item.price) *
                        item.amount
                      ).toFixed(2)
                    )}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix="$"
                  />{" "}
                </div>
              </div>
              <hr style={productBorder} />
            </>
          ))}

          <div style={head}>
            <div style={nombre}> Subtotal </div>
            <div style={precioUnitario}> </div>
            <div style={cantidad}> </div>
            <div style={totalArticulo}>
              <CurrencyFormat
                value={price}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix="$"
                style={strongText}
              />{" "}
            </div>
          </div>

          <div style={{ ...row, justifyContent: "space-between" }}>
            <div style={strongText}>Envío</div>
            <div style={{ ...strongText, color: "#E21608" }}>
              {price === 0 && 0}
              {price > 0 &&
                (price >= 549 && price > 0 ? "¡Gratis!" : "$ 149.00")}
            </div>
          </div>

          <Box style={rowMini} sx={{ fontSize: { xs: "12px", md: "15px" } }}>
            <div>
              Tiempo de entrega estimado es de 2 a 5 días hábiles según el
              domicilio de entrega
            </div>
            <img src={ship} alt="img" style={{ height: "40px" }} />
          </Box>
          {saving > 0 && (
            <Box
              style={{ ...row, justifyContent: "space-between" }}
              sx={{ fontSize: { xs: "15px", md: "17px" } }}
            >
              <div style={strongText}> Ahorras </div>
              <div style={strongText}>
                <CurrencyFormat
                  value={saving}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  prefix="-$"
                  style={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: "700",
                    color: "#E21608",
                  }}
                />{" "}
              </div>
            </Box>
          )}

          <hr />
          <div style={head}>
            <div style={nombre}> TOTAL A PAGAR </div>
            <div style={precioUnitario}> </div>
            <div style={cantidad}> </div>
            <div style={totalArticulo}>
              <CurrencyFormat
                value={parseFloat(price) + parseFloat(price >= 549 ? 0 : 149)}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix="$"
                style={strongText}
              />{" "}
            </div>
          </div>
        </div>
      </Box>
      <Box
        component="div"
        sx={{ display: { xs: "flex", md: "none" }, textAlign: "left" }}
        style={{ marginBottom: "15px" }}
      >
        <div
          style={{
            ...card,
            backgroundColor: "white",
            border: "0",
            borderRadius: "0",
          }}
        >
          <div style={head}>
            <div
              style={{
                ...nombre,
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
              }}
            >
              {" "}
              Artículos{" "}
            </div>
          </div>
          <hr />
          {items.map((item) => (
            <>
              <div
                style={{
                  display: "flex",
                  fontFamily: "Nunito Sans, sans-serif",
                  fontSize: "13px !important",
                  fontWeight: "400",
                }}
              >
                <div style={{ ...column, ...inheritFont }}>
                  <div style={{ marginBottom: "10px", ...inheritFont }}>
                    {item.name}{" "}
                    {item.description !== "" ? `(${item.description})` : ""}
                  </div>
                  <div style={inheritFont}>
                    Precio unit:{" "}
                    <CurrencyFormat
                      value={item.price}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      style={{ fontFamily: "inherit", fontSize: "inherit" }}
                    />
                  </div>
                  <div style={{ ...inheritFont, fontWeight: "900" }}>
                    Precio{" "}
                    <CurrencyFormat
                      value={Number((item.price * item.amount).toFixed(2))}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      prefix="$"
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        fontWeight: "500",
                      }}
                    />
                  </div>
                </div>
                <div style={inheritFont}>
                  <img
                    src={item.url}
                    alt="img"
                    style={{ width: "90px", height: "90px" }}
                  />
                  <div style={inheritFont}> Cantidad: {item.amount}</div>
                </div>
              </div>
              <hr />
            </>
          ))}
          <div
            style={{
              ...head,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              marginBottom: "0",
              paddingBottom: "0",
            }}
          >
            <div style={inheritFont}> Subtotal </div>
            <div style={inheritFont}>
              {" "}
              ${" "}
              <CurrencyFormat
                value={price}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                style={inheritFont}
              />{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              margin: "5px",
            }}
          >
            <div style={inheritFont}> Envío </div>
            <div>
              {" "}
              {price >= 549 ? (
                <Box
                  component="span"
                  color="error.main"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                  }}
                >
                  ¡GRATIS!
                </Box>
              ) : (
                "$ 149.00"
              )}{" "}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div>
              <div style={rowMiniMobile}>Envío estándar</div>
              <div style={{ ...rowMiniMobile, fontSize: "10px" }}>
                Tiempo de entrega estimado es de 2 a 5 días hábiles según el
                domicilio de entrega
              </div>
            </div>
            <img src={ship} alt="img" style={{ height: "40px" }} />
          </div>
          <hr />
          <div
            style={{
              ...head,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              margin: "5px",
            }}
          >
            <div style={inheritFont}> TOTAL A PAGAR </div>
            <div style={inheritFont}>
              {" "}
              ${" "}
              <CurrencyFormat
                value={parseFloat(price) + parseFloat(price >= 549 ? 0 : 149)}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                style={inheritFont}
              />{" "}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}
