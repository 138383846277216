import React from "react";

/* Sections */
import BestSellerSection from "./Sections/BestSeller/BestSellerSection";
import TipsSection from "./Sections/TipsSection";
import ShoppingInfo from "./Sections/ShoppingInfo";
import ProductSections from "./Sections/ProductSections.js";

import Box from "@mui/material/Box";

import Banner from "views/Banner/Banner";
import { Helmet } from "react-helmet";

function HomePage() {
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Kit Afinación Transmisión Automática | Afinazone | Refacciones y
          Mantenimiento{" "}
        </title>
        <meta
          name="description"
          content="Compra tu kit de afinación para transmisión automática compatible con tu auto: filtro, aceite ATF, cárter, juntas, enfriador, sensor. Envíos a todo México."
        />
        <meta
          name="keywords"
          content="Refacciones y kits para la afinación de tu transmisión"
        />
      </Helmet>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Banner></Banner>
      </Box>
      <ProductSections />
      <BestSellerSection />
      <TipsSection />
      <ShoppingInfo />
    </div>
  );
}

export default HomePage;
