import { useLocation } from "react-router-dom";
import { getDiscounts } from "utils/API/DescuentosAPI";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const PRODUCT = {
  KAT: "Kit de Afinación para Transmisión",
  Ac: "Aceite",
  Cart: "Cárters",
  Enf: "Enfriadores",
  KAM: "Kit de Afinación para Motor",
  CaAc: "Cambio de Aceite",
  Flt: "Filtros",
  Bj: "Bujías",
  Accs: "Accesorios",
};

export const SLUG = {
  "kits-afinacion-transmision": "KAT",
  "aceites-afinazone": "Ac",
  "carters-motor-transmision-automatica-afinazone": "Cart",
  "enfriadores-aceite-afinazone": "Enf",
  KAM: "KAM",
  CaAc: "CaAc",
  Flt: "FLt",
  Bj: "Bj",
  Accs: "Accs",
};

export function validateField(target) {
  if (typeof target === "string") {
    return target !== "";
  }
  return false;
}

export function validateNumber(target) {
  var number = parseFloat(target);
  if (isNaN(number)) return false;
  return true;
}

export function convertToShoppingCartElement() {
  var shoppingCart = localStorage.getItem("carrito");
  if (!shoppingCart) return null;
  shoppingCart = shoppingCart.split("+|+");
  shoppingCart = shoppingCart.map((item) => {
    return { ...JSON.parse(item), disableOil: true };
  });
  return shoppingCart;
}

export function saveShoppingCar(newShoppingCar) {
  var saveShoppingCar = [...newShoppingCar.map((item) => JSON.stringify(item))];
  saveShoppingCar = saveShoppingCar.join("+|+");
  localStorage.setItem("carrito", saveShoppingCar);
}

export function formatTicket(numero, longitud) {
  // Convierte el número a una cadena
  let numeroCadena = numero.toString();

  // Calcula cuántos ceros a la izquierda se necesitan
  const cerosNecesarios = longitud - numeroCadena.length;

  // Agrega ceros a la izquierda si es necesario
  if (cerosNecesarios > 0) {
    numeroCadena = "0".repeat(cerosNecesarios) + numeroCadena;
  }

  return numeroCadena;
}

export function formatCurrency(numero) {
  // Verificar si el número es un número válido
  if (isNaN(numero)) {
    return "Número inválido";
  }

  // Redondear el número a 2 decimales
  const numeroFormateado = numero.toFixed(2);

  // Separar la parte entera de la parte decimal
  const partes = numeroFormateado.toString().split(".");

  // Formatear la parte entera con comas cada 3 dígitos
  partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Unir la parte entera, el símbolo "$" y la parte decimal con un punto
  return "$" + partes.join(".");
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getDiscountsInProducts = async (products) => {
  const discounts = await getDiscounts(); // Assume this returns an object with discounts keyed by type

  // Modify products in place
  products.forEach((item) => {
    const discountPercentage = discounts[item.type] || 0; // Get discount for the product type, default to 0
    item.discount = false;
    if (discountPercentage > 0) {
      item.discount = true;
      if (item.disableOil) {
        item.discountPrice =
          item.price - (item.price * discountPercentage) / 100; // Calculate discounted price
        item.discountPrice = parseFloat(item.discountPrice).toFixed(2);
      } else {
        item.liters.forEach((item) => {
          item.discountPrice =
            item.price - (item.price * discountPercentage) / 100; // Calculate discounted price
          item.discountPrice = parseFloat(item.discountPrice).toFixed(2);
        });
      }
    } else {
      delete item.discountPrice;
    }
  });

  return products; // Return the modified products array
};

export const getDiscountsInProduct = async (item) => {
  const discounts = await getDiscounts(); // Assume this returns an object with discounts keyed by type

  const discountPercentage = discounts[item.type] || 0; // Get discount for the product type, default to 0
  item.discount = false;
  if (discountPercentage > 0) {
    item.discount = true;
    if (item.disableOil) {
      item.discountPrice = parseFloat(
        item.price - (item.price * discountPercentage) / 100
      ).toFixed(2); // Calculate discounted price
    } else {
      item.liters.forEach((item) => {
        item.discountPrice = parseFloat(
          item.price - (item.price * discountPercentage) / 100
        ).toFixed(2); // Calculate discounted price
      });
    }
  }

  return item;
};
